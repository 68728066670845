import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import Editions from './Pages/Editions'
import Commands from './Pages/Commands'
import 'aos/dist/aos.css'
import './Styles/index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <div className='background'></div>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='editions' element={<Editions />} />
        <Route path='commands' element={<Commands />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
